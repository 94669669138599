export default {
  OTP_LENGTH: 6,
  TEMP_IMAGE: "https://picsum.photos/id/1060/600/400",
  FB_INVALID_PHONE_CODE: "auth/invalid-phone-number",
  FB_INCORRECT_OTP: "auth/invalid-verification-code"
};
export const PROMOTION = {
  BY: 'trade_by',
  PREFIX_CUSTOMER: 'cus_',
  DISCOUNT: {
      PERCENT: 'percent_discount',
      MONEY: 'money_discount',
      CASH_VOUCHER: 'cash_voucher',
      FREE_POINT: 'free_point',
  },
  ON: {
      BILL: 'bill',
      ITEM: 'item',
      EVENT: 'event',
      FEESHIP: 'feeship',
  },
  LITERAL_TYPE: {
      ITEM_DISCOUNT: 'item_discount',
      BILL_DISCOUNT: 'bill_discount',
      VOUCHER: 'voucher',
      FREE_POINT: 'free_point'
  }
}