<template>
    <v-snackbar top color="danger" v-model="snackBarShow" :timeout="timeout">
        {{ mesage }}
    </v-snackbar>
</template>
<script>
export default {
    name: "ErrorSnackbar",
    data() {
        return {
            snackBarShow: false,
        };
    },
    watch: {
        isShow(newVal){
            this.snackBarShow = newVal;
        }
    },
    props: {
        isShow: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
        mesage: {
            type: String,
            default: function () {
                return "";
            },
        },
        timeout: {
            type: Number,
            default: function () {
                return 5000;
            },
        },
    },
};
</script>