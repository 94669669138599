import session from "./session";
import { RootAPI } from "./index";

export default {
  checkPhoneNumber(params) {
    const queryString = "check_phone_number?username=" + params;
    return session().get(RootAPI + "v2/customer/" + queryString);
  },

  login(params) {
    return session().post(RootAPI + "v2/login/", params);
  },

  customerRegister(params) {
    return session().post(RootAPI + "v2/customer/register.json", params);
  },
  customerResetPassword(params) {
    return session().post(RootAPI + "v2/customer/_/fa_reset_password/", params);
  },
  checkAccountCustomer(params) {
    return session().post(RootAPI + "v2/customer/_/check_user_account/", params);
  },
  customerSetPassword(params) {
    return session().post(RootAPI + "v2/customer/update_password/", params);
  },
  customerUpdateProfile(params) {
    return session().post(RootAPI + "v2/customer/update_profile/", params);
  },
  customerLoginLog(data) {
    return session().post(RootAPI + "v2/customer/save_login_log/", data);
  },
  getMembershipInfo(customerId) {
    return session().get(RootAPI + 'v2/customer/' + customerId+'/profile/');
  },
  getPointHistory(customerId){
    // id 250952 for test
    return session().get(RootAPI + 'customer/' + customerId + "/point-his/");
  },
  getPaymentHistory(customerId){
    // id 250939 for test
    return session().get(RootAPI + 'v2/customer/' + customerId + "/payment-history?user_isnull=true/");
  },
  fetchCustomerPromotion(params) {
    return session().post(RootAPI + `v2/promotion/code/`, params);
  },
  fetchInvidualPromotion(params) {
    return session().post(RootAPI + `v2/promotion/my-promotion/`, params);
  },
};
