import i18n from "@/i18n"

const initializeState = () => {
	return {
		locale: null,
	}
}

const state = initializeState()

const mutations = {
	setLocale(state, payload) {
		state.locale = payload
		i18n.locale = payload
	},
}

const getters = {
	locale: (state) => state.locale,
}

const actions = {
	changeLocale({ commit }, locale) {
		if (locale !== i18n.locale && Object.keys(i18n.messages).includes(locale)) {
			commit("setLocale", locale)
		}
	},
}

export const locale = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
