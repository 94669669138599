import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
import {loadLocale} from "@/i18n"

Vue.use(VueRouter)

const routes = [
	{
		path: "/sign-up",
		name: "SignUp",
		component: () => import("@/views/SignUp.vue"),
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login.vue"),
	},
	{
		path: "/forgot-password",
		name: "forgot-password",
		component: () => import("@/views/ForgotPassword.vue"),
	},
	// {
	// 	path: "/edit-profile",
	// 	name: "edit-profile",
	// 	component: () => import("@/views/Account.vue"),
	// },
	{
		path: "/change-password",
		name: "ChangePassword",
		component: () => import("@/views/ChangePassword.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/promotion",
		name: "Promotion",
		component: () => import("@/views/YourPromotion.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/error",
		name: "error",
		component: () => import("@/views/Error.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/marketing",
		name: "MarketingPage",
		component: () => import("@/views/MarketingPage.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/edit-profile",
		name: "Account",
		component: () => import("@/views/Account.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/membership",
		name: "Membership",
		component: () => import("@/views/Membership.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/transaction-history",
		name: "TransactionHistory",
		component: () => import("@/views/TransactionHistory.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/:brandCode?/:storeCode?",
		name: "home",
		component: () => import("../views/Home.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	const isLoggedIn = store.getters["auth/isAuthenticated"]
	const brandCode = to.params.brandCode
	if (brandCode != null) {
		store.commit("store/setBrandCode", brandCode)
	}
	const storeCode = to.params.storeCode
	if (storeCode != null) {
		store.commit("store/setStoreCode", storeCode)
	}
	const checkAuthorize = function(to, from, next) {
		if (to.matched.some((record) => record.meta.requiresAuth)) {
			if (isLoggedIn) {
				next()
				return
			}
			next("/login")
		} else {
			if (isLoggedIn) {
				next("/")
				return
			}
			next()
		}
	}

	
	store.dispatch("screen/resetState");
	loadLocale();
	checkAuthorize(to, from, next)
})

export default router
