import moment from "moment";
import Constant from "@/common/constant";

export default {
  /**
   * Validate image link
   * @param {*} imageLink
   */
  isValidImageLink(imageLink) {
    // let regex = /(http(s?):)|([/|.|\w|\s])*\.(?:jpg|gif|png|jpeg)/
    return /\.(gif|jpe?g|tiff|png|webp|bmp)$/i.test(imageLink);
  },
  /**
   * Validate email address
   * @param {*} email
   */
  isValidEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  /**
   * Validate phone number
   */
  isValidPhoneNumber(phone) {
    // var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const re = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
    return re.test(phone);
    // console.lo,g(regex.test(phone))
  },

  /**
   * Validate OTP. Numeric. Length = 6
   */
  isValidOTP(otp) {
    return otp && !isNaN(otp) && otp.length === Constant.OTP_LENGTH;
    // return true;
  },

  /**
   * Interger only
   */
  intergerOnly(valueInput) {
    if (valueInput != "") {
      valueInput = valueInput.replace(/[^0-9-]+/g, "");
    }
    return valueInput;
  },

  formatStrToDate(strDate) {
    if (strDate === null || strDate === "") {
      return;
    }
    let yyyy = strDate.substring(0, 4);
    let mm = strDate.substring(4, 6);
    let dd = strDate.substring(6, 8);
    return yyyy + "-" + mm + "-" + dd;
  },

  formatPhone(username) {
    if (!username) return "";
    if(username.startsWith("84")){
      username = "+" + username;
    }
    username = parseInt(username.replace("+84", "0"));
    username = "+84" + username;
    return username;
  },

  toPhoneNumber(phoneNumber){
    if (!phoneNumber) return "";
    if(phoneNumber.startsWith("84")){
      phoneNumber = "+" + phoneNumber;
    }
    phoneNumber = phoneNumber.replace("+84", "0");
    return phoneNumber;
  },

  getPriceNonVAT(price, stuckb) {
    price = parseInt(price, 10);
    switch (parseInt(stuckb, 10)) {
      case 0:
        return price;
      case 1:
        return price;
      case 2:
        return Math.round(price / 1.1);
      default:
        return price;
    }
  },

  getOnlyVAT(price, stuckb) {
    switch (parseInt(stuckb, 10)) {
      case 0:
        return 0;
      case 1:
        return Math.round(price * 0.1);
      case 2:
        return Math.round(price - price / 1.1);
      default:
        return Math.round(price * 0.1);
    }
  },

  getPriceIncludingVAT(price, stuckb) {
    switch (parseInt(stuckb, 10)) {
      case 0:
        return price;
      case 1:
        return Math.round(price * 1.1);
      case 2:
        return price;
      default:
        return price;
    }
  },

  // sotozei = VAT of items with stuckb = 1
  getSotozei(price, quantity, stuckb) {
    if (parseInt(stuckb, 10) !== 1) return 0;

    const priceSum = parseInt(price, 10) * parseInt(quantity, 10);
    return Math.round(priceSum * 0.1);
  },

  // uchizei = VAT of items with stuckb = 2
  getUchizei(price, quantity, stuckb) {
    if (parseInt(stuckb, 10) !== 2) return 0;

    const priceSum = parseInt(price, 10) * parseInt(quantity, 10);
    return Math.round(priceSum - priceSum / 1.1);
  },

  formatDate(value) {
    if (value == undefined || value == "") return "";
    return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
  },

  formatToOrderTimeNumber(value) {
    if (value == undefined || value == "") return "";
    return Number(moment(String(value)).format("HHmm"));
  },

  /**
   * Format money x,xxx,xxx
   * @param {} strItem
   */
  formatMoney(strItem) {
    ``;
    if (strItem === null || strItem === "") {
      return;
    }
    return String(strItem).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  }
};
