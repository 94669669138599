import CustomerAPI from "@/api/customer"
import i18n from "@/i18n"

const initializeState = () => {
	return {
		allowAnonymous: false,
		token: null,
		refreshToken: null,
		currentUser: null,
		status: null,
		isStaff: null,
		isPhoneAvailable: null,
	}
}

const state = initializeState()

const mutations = {
	/**
	 * Set main token
	 * @param state
	 * @param token
	 */
	setToken(state, data) {
		state.token = data.access
		state.refreshToken = data.refresh
		state.currentUser = data.customer != null ? data.customer : data.user
		state.isStaff = data.user
		localStorage.setItem("EIV_TOKEN", data.access)
	},

	setStatus(state, data) {
		state.status = data
	},

	setAllowAnonymous(state, data) {
        state.allowAnonymous = data;
    },

	resetState(state) {
		Object.assign(state, initializeState())
	},

	setPhoneAvailable(state, payload) {
		state.isPhoneAvailable = payload
	},

}

const getters = {
	authDataGet: (state) => {
		if (state.currentUser) {
			return {
				fullname: state.currentUser.full_name,
				phone: state.currentUser.user_name,
				email: state.currentUser.email,
				id: state.currentUser.id,
				is_staff: state.isStaff.is_staff,
				dateOfBirth: state.currentUser.date_of_birth
			}
		}
		return {
			fullname: "",
			phone: "",
			email: "",
			id: "",
			is_staff: "",
			dateOfBirth: ""
		}
	},
	authToken: (state) => {
		return {
			token: state.token,
			refreshToken: state.refreshToken,
			user: state.currentUser,
		}
	},
	isAuthenticated: (state) => !!state.currentUser,
	isPhoneAvailable: (state) => state.isPhoneAvailable,
}

const actions = {
	async customerLogin({ commit }, payload) {
		commit("screen/setError", null, { root: true })
		commit("screen/setLoading", true, { root: true })
		await CustomerAPI.login(payload)
			.then(({ data }) => {
				commit("setToken", data)
			})
			.catch(() => {
				commit("screen/setError", i18n.t("login.failLogin"), { root: true })
			})
			.finally(() => {
				commit("screen/setLoading", false, { root: true })
			})
	},

	customerLogout({ commit }) {
		commit("resetState")
	},

	async customerRegister({ commit }, payload) {
		commit("screen/setError", null, { root: true })
		commit("screen/setLoading", true, { root: true })
		await CustomerAPI.customerRegister(payload)
			.then(({ data }) => {
				commit("setToken", data)
			})
			.catch(() => {
				commit("screen/setError", i18n.t("signUp.failRegister"), { root: true })
			})
			.finally(() => {
				commit("screen/setLoading", false, { root: true })
			})
	},
	async customerResetPassword({ commit }, payload) {
		commit("screen/setError", null, { root: true })
		commit("screen/setLoading", true, { root: true })
		await CustomerAPI.customerResetPassword(payload)
			.then(({ data }) => {
				commit("setToken", data)
			})
			.catch(() => {
				commit("screen/setError", i18n.t("error.common"), { root: true })
			})
			.finally(() => {
				commit("screen/setLoading", false, { root: true })
			})
	},
	async customerUpdatePassword({ commit }, payload) {
		commit("screen/setError", null, { root: true })
		commit("screen/setLoading", true, { root: true })
		await CustomerAPI.customerSetPassword(payload)
			.then(({ data }) => {
				commit("setToken", data)
			})
			.catch(() => {
				commit("screen/setError", i18n.t("error.common"), { root: true })
			})
			.finally(() => {
				commit("screen/setLoading", false, { root: true })
			})
	},
	async checkAccountCustomer({ commit }, payload) {
		commit("screen/setError", null, { root: true })
		commit("screen/setLoading", true, { root: true })
		await CustomerAPI.checkAccountCustomer(payload)
			.then(() => {
				// commit("setToken", data);
			})
			.catch((error) => {
				commit("screen/setError", error.message, { root: true })
			})
			.finally(() => {
				commit("screen/setLoading", false, { root: true })
			})
	},
	async checkPhoneNumber({ commit }, payload) {
		commit("screen/setError", null, { root: true })
		commit("screen/setLoading", true, { root: true })
		commit("setPhoneAvailable", null)

		await CustomerAPI.checkPhoneNumber(payload)
			.then(({ data }) => {
				commit("setPhoneAvailable", !data.exists)
			})
			.catch((error) => {
				commit("screen/setError", error.message, { root: true })
			})
			.finally(() => {
				commit("screen/setLoading", false, { root: true })
			})
	},
	resetPhoneStatus({ commit }) {
		commit("setPhoneAvailable", null)
	},
	async customerUpdateProfile({ commit }, payload) {
        commit('setError', null);
        await CustomerAPI.customerUpdateProfile(payload)
            .then(({ data }) => {
                commit('setToken', data);
            })
            .catch(error => {
                commit('setError', error.message);
            });
    },
}

export const auth = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
