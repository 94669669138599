const initializeState = () => {
	return {
		loading: null,
		error: null,
	}
}

const state = initializeState()

const mutations = {
	setLoading(state, payload) {
		state.loading = payload
	},
	setError(state, payload) {
		state.error = payload
	},
}

const getters = {
	isLoading: (state) => !!state.loading,
	error: (state) => state.error
}

const actions = {
	resetState({ commit }) {
		commit("setLoading", false)
		commit("setError", null)
	},
}

export const screen = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
