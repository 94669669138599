import CustomerAPI from "@/api/customer"
import i18n from "@/i18n"

const initializeState = () => {
	return {
		storeCode: null,
		brandCode: null,
		isAccessWifi: null,
	}
}

const state = initializeState()

const mutations = {
	setStoreCode(state, payload) {
		state.storeCode = payload
	},
	setBrandCode(state, payload) {
		state.brandCode = payload
	},
	setIsAccessWifi(state, payload) {
		state.isAccessWifi = payload
	},
}

const getters = {
	isAccessWifi: (state) => state.isAccessWifi,
}

const actions = {
	async sendUserLoginLog({ state, commit }) {
		commit("screen/setError", null, { root: true })
		commit("screen/setLoading", true, { root: true })
		commit("setIsAccessWifi", null)
		CustomerAPI.customerLoginLog({
			store_code: state.storeCode,
			brand_code: state.brandCode,
		})
			.then(({ data }) => {
				console.log(data);
				if (data.success === false) {
					commit("screen/setError", data.mess, { root: true })
					commit("setIsAccessWifi", false)
				} else {
					commit("setIsAccessWifi", true)
				}
			})
			.catch(() => {
				commit("screen/setError", i18n.t("error.common"), { root: true })
				commit("setIsAccessWifi", false)
			})
			.finally(() => {
				commit("screen/setLoading", false, { root: true })
			})
	},
}

export const store = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
