// index.js
// import en from "@/assets/lang/en.json"
// import vi from "@/assets/lang/vi.json"
// import ja from "@/assets/lang/ja.json"
import Vue from "vue"
import VueI18n from "vue-i18n"
import store from "@/store"

Vue.use(VueI18n)

const defaultLocale = "gb-eng"

export function loadLocale() {
	const locale = store.getters["locale/locale"]
	if (locale) {
		i18n.locale = store.getters["locale/locale"]
	} else {
		i18n.locale = defaultLocale
	}
}
// Load all locales and remember context
function loadMessages() {

	const context = require.context("@/assets/lang", true, /[a-z0-9-_]+\.json$/i)

	const messages = context
		.keys()
		.map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
		.reduce(
			(messages, { key, locale }) => ({
				...messages,
				[locale]: context(key),
			}),
			{}
		)

	return { context, messages }
}

const { context, messages } = loadMessages()

export function loadHotModule(){
	// Hot updates
	if (module.hot) {
		module.hot.accept(context.id, () => {
			const { messages: newMessages } = loadMessages()
	
			Object.keys(newMessages)
				.filter((locale) => messages[locale] !== newMessages[locale])
				.forEach((locale) => {
					messages[locale] = newMessages[locale]
					i18n.setLocaleMessage(locale, messages[locale])
				})
		})
	}
}

// VueI18n instance
const i18n = new VueI18n({
	locale: defaultLocale,
	messages,
})
export default i18n